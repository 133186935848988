.order_taxi_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    padding: 1%;
    background: #fff;
  }
  
  .title{
    color: #333;
  }
  .title > b{
    color: #FFBE30;
  }
  
  .taxi_form {
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  button {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background: #fff;
    border: 1px solid #8E8E8E;
  }
  .tashkentBtn{
    width: 150px;
    background: #fff;
    border: 1px solid #8E8E8E;
  }
  button.active {
    width: 150px;
    background-color: #FFBE30;
    color: #000;
    border: 1px solid #FFBE30;
  }
  
  select {
    padding: 10px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #8E8E8E;
    border-radius: 5px;
    font-size: 16px;
    background: #fff;
  }
  
  input[type='date'] {
    padding: 10px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #8E8E8E;
    border-radius: 5px;
    font-size: 16px;
    background: #fff;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
  
  .passengerBtns {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    gap: 3%;
  }
  .passengerBtns >   button.active {
    width: auto !important;
    background-color: #FFBE30;
    color: #000;
    border: 1px solid #FFBE30;
  }
  
  @media screen and (max-width: 500px) {
    input[type='date'] {
      width: 92%; 
    }
  }